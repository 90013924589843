<template>
  <div id="contacts" class="space-y-3 font-semibold min-h-screen">
    <h1 class="text-4xl py-10">Контакты</h1>
    <p class="text-gray-700">
      По всем вопросам, связанным с работой нашей фирмы обращайтесь по ниже
      приведенным контактам:
    </p>
    <p>
      <span class="text-gray-600">Телефон: </span>
      <a
        href="tel:+79295732073"
        class="text-blue-500 hover:text-blue-700 hover:underline"
        >+7 (929) 573-20-73</a
      >
    </p>
    <p>
      <span class="text-gray-600">Почта: </span>
      <a
        href="mailto:ruvenzori@list.ru"
        class="text-blue-500 hover:text-blue-700 hover:underline"
        >ruvenzori@list.ru</a
      >,
      <a
        class="text-blue-500 hover:text-blue-700 hover:underline"
        href="mailto:m01@ryvenzori.ru"
        >m01@ryvenzori.ru</a
      >
    </p>
    <p>
      <span class="text-gray-600">Адрес: </span> 249096, Калужская область,
      Малоярославецкий р-н, г Малоярославец, Калужская ул, д. 9, офис 2
    </p>
    <iframe
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A0ef8ab110a358e6bd8d4dd3b7a554270ad3d1a9c085a370fcefcbe3ed0e67331&amp;source=constructor"
      width="1000"
      height="400"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
