<template>
  <div id="app">
    <Wrapper />
  </div>
</template>

<script>
import Wrapper from './components/Wrapper'
export default {
  name: 'App',
  components: {
    Wrapper
  }
}
</script>

