<template>
  <div id="about" class="min-h-screen">
      <h1 class="text-3xl text-center py-5 font-bold">О нас</h1>
       
      <div class="space-y-3 text-lg">
        <p>Наша компания занимается продажей промышленного оборудования уже более 7 лет. Мы знаем, какое оборудование необходимо именно Вам. Сервис менеджеры ООО “РУВЕНЗОРИ” всегда готовы оказать поддержку наших клиентов.</p>
        <p>Мы занимаемся поставками производственных линий, запасные частей, комплектующих, инструментов и расходных материалов производства ведущих мировых производителей для оборудования предприятий различных отраслей промышленности: деревообработка, мебельное производство, автомобилестроение, строительная индустрия, коммунальное хозяйство, металлообработка, производство слоистых пластиков, производство стекла, пищевая промышленность.</p>
        <p>Также нашей деятельностью является реализация запасных частей для коммерческого транспорта.</p>
        <p>Долговременный опыт работы позволяет нам находить удобные для потребителя варианты сотрудничества.</p> 
        <p>Вы получаете выгодные условия: качество, отвечающее требованиям современного производства; гарантии производителя; доступные цены; оптимальные сроки поставок; гибкую систему скидок. В зависимости от требований клиентов, все необходимое для производства может быть им предоставлено со склада в Москве.</p>
      </div>
      <h1 class="text-3xl text-center font-bold my-5">Наши преимущества</h1>
      <div class="lg:flex block md:space-x-5 text-lg py-4">
      <ul class="space-y-3">
        <li>- Низкие цены;</li>
        <li>- Быстрая доставка из Европы до нашего склада в Москве;</li>
        <li>- Оригинальный качественный товар;</li>
        <li>- Консультирование по продаваемым продуктам с нашими компетентными специалистами;</li>
        <li>- Минимальная гарантия от производителя - от одного года;</li>
        <li>- Техническая поддержка;</li>
        <li>- При необходимости, консультирование с немецкими производителями запрашиваемого товара.</li>

      </ul>
      <img :src="require('/public/img/about/about.jpg')" alt="logo" 
        class="sm:py-2 md:py-0 h-1/2 lg:w-1/3 mx-auto w-full ">
      </div>
  </div>    
</template>

<script>
export default {

}
</script>

<style>

</style>